import React from 'react'

const ErrorRoute = React.lazy(() => import('./views/errorPage'))
const LoginRoute = React.lazy(() => import('./views/login'))
const HomeRoute = React.lazy(() => import('./views/home'))
const Dashboard = React.lazy(() => import('./views/dashboard'))
const CreateMission = React.lazy(() => import('./views/missions/agenceMissionCreate'))
const CreateSacExpedition = React.lazy(() => import('./views/sacs/singleCurrentMissionPage'))
const mapSupervision = React.lazy(() => import('./maps/map'))

const routes = [
    { path: '/error', exact: true, name: 'Error',element:ErrorRoute },
    { path: '/login', exact: true, name: 'Login',element:LoginRoute },
    { path: '/home', exact: true, name: 'Home',element:HomeRoute },
    { path: '/', exact: true, name: 'Dash',element:Dashboard },
    { path: '/missions/agence/create', exact: true, name: 'CreateMission',element:CreateMission },
    { path: '/missions/agence/expedition', exact: true, name: 'Expedition',element:CreateSacExpedition },
    { path: '/maps', exact: true, name: 'Maps',element:mapSupervision },
]

export default routes