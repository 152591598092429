import React from 'react';
import { Navigate } from 'react-router-dom';
import { verifyAuth } from './verifyAuth';

const ProtectedRoute = ({ element: Component }) => {
  return verifyAuth() ? (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Component />
    </React.Suspense>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
