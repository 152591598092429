function checkAuthCookies() {
    try {
        
      const getCookie = name => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(";").shift();
        return null;
      };
  
      const token = getCookie("token");
      const roles = getCookie("roles");
      const employeeId = getCookie("employeeId");
      const employeeName = getCookie("employeeName");
      const agenceId = getCookie("agenceId");
  
      if (token && roles && employeeId && employeeName && agenceId) {
        return { token, roles, employeeId, employeeName, agenceId };
      }
      return null;
    } catch (error) {
      return null;
    }
  }
  
  function checkAuthLocalStorage() {
    try {
      const token = localStorage.getItem("token");
      const roles = localStorage.getItem("roles");
      const employeeId = localStorage.getItem("employeeId");
      const employeeName = localStorage.getItem("employeeName");
      const agenceId = localStorage.getItem("agenceId");
  
      if (token && roles && employeeId && employeeName && agenceId) {
        return { token, roles, employeeId, employeeName, agenceId };
      }
      return null;
    } catch (error) {
      return null;
    }
  }
  
  export function verifyAuth() {
    try {
      const verifyInCookies = checkAuthCookies();
      const verifyInLocalStorage = checkAuthLocalStorage();
  
      if (verifyInCookies && verifyInLocalStorage) {
        return true;
      } else if (verifyInCookies || verifyInLocalStorage) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
  
  export function getInfosAuth() {
    try {
      const verifyInCookies = checkAuthCookies();
      const verifyInLocalStorage = checkAuthLocalStorage();
      return verifyInCookies || verifyInLocalStorage || null;
    } catch (error) {
      return null;
    }
  }

  export function getToken() {
    try {
      const verifyInCookies = checkAuthCookies();
      const verifyInLocalStorage = checkAuthLocalStorage();
      return verifyInCookies.token || verifyInLocalStorage.token || null;
    } catch (error) {
      return null;
    }
  }

  export function getAgenceId() {
    try {
      const verifyInCookies = checkAuthCookies();
      const verifyInLocalStorage = checkAuthLocalStorage();
      return verifyInCookies.agenceId || verifyInLocalStorage.agenceId || null;
    } catch (error) {
      return null;
    }
  }
  



