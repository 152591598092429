import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import routes from './dom.router.config';
import ProtectedRoute from './auth/protectedRoute';

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {routes.map((route, idx) => {
            if (route.path === '/login') {
              
              return (
                <Route
                  key={idx}
                  path={route.path}
                  element={<route.element />}
                />
              );
            } else {
             
              return (
                <Route
                  key={idx}
                  path={route.path}
                  element={<ProtectedRoute element={route.element} />}
                />
              );
            }
          })}
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
